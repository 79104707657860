.gray-bg {
  background-color: #f3f3f4;
}

.logo-name {
  height: 155px;
  width: 300px;
  background: url("../images/logo.png");
  //background-size: 100px 100px;
}

.middle-box {
  max-width: 300px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
  h1 {
    font-size: 170px;
  }
}

.lockscreen {
  &.middle-box {
    width: 200px;
    padding-top: 110px;
  }
}

.block-btn {
  height: 33px;
}

.login-captcha {
  width: 80px;
  height: 34px;
}